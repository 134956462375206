import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import intersection from "lodash/intersection";
import { ICheckoutOffersProps } from "./CheckoutOffersProps";

import { convertArrayToObject } from "../../../services/helpers";
import { setCheckout } from "../../../redux/slices/Checkout/checkout";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { RateList, RateListItem } from "./CheckoutOffersHelpers";
import IRoom from "../../../@types/IRoom";
import { selectRateContainer } from "./CheckoutOffers.module.scss";
import { selectHotelRates } from "../../../redux/slices/Rate/rate";
import { TP_REDEMPTION_RATE } from "../../../@types/Constants";
import { checkUserRedemptionEligiblity } from "../../../services/redemption";
import differenceInDays from "date-fns/differenceInDays";
import { getMemberPoints } from "../../../services/redemption";
import { setMemberPointsTally } from "../../../redux/slices/Member/member";

const CheckoutOffers: React.FC<ICheckoutOffersProps> = ({
  rooms,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();
  const [offers, setOffers] = useState([]);
  const [currentRate, setCurrentRate] = useState(null);
  const checkout = useAppSelector((state) => state.checkout);
  const search = useAppSelector((state) => state.search);
  const isLoggedIn = useAppSelector((state) => state.member.isLoggedIn);
  const memberProfile = useAppSelector((state) => state.member.crmProfile);
  const rates = useAppSelector(
    selectHotelRates([checkout.HotelCode as string])
  );
  const hasFees = Object?.values(rooms)?.some(
    (room) =>
      room?.room?.BaseRateFees > 1 ||
      (room?.room?.FromRateFeeItems && room?.room?.FromRateFeeItems?.length > 0)
  );
  const perNight =
    Object?.values(rooms)?.length > 1
      ? `avg. per night${hasFees ? " with fees" : ""}`
      : `per night${hasFees ? " with fees" : ""}`;
  const [moveSelectedOfferToTop, setMoveSelectedOfferToTop] = useState(true);
  const [redemptiomRateFilterFlag, setredemptiomRateFilterFlag] =
    useState(false);
  const [offersSortOrder, setOffersSortOrder] = useState([]);
  useEffect(() => {
    const rateArrays = [];
    let offerCodes = [];
    let selectedPrice = 0;
    const roomsArray = Object.values(rooms);
    let selectedRate = {
      ...roomsArray[0].rate,
      Active: true,
    };
    let roomOffers = {};
    const isMultiRoom = roomsArray.length > 1;
    roomsArray.forEach(function (room) {
      if (room.room && room.rate) {
        roomOffers = { ...roomOffers, [room.id]: { Rates: {} } };
        const rateArray = [];
        const rateSelected = room.rate;
        if (selectedRate.RateCode === rateSelected.RateCode) {
          selectedRate.name = rateSelected.name;
          selectedRate.description = rateSelected.description;
          selectedRate.rateCode = rateSelected.rateCode;
          selectedRate.rateType = rateSelected.rateType;
          selectedRate.MultiRates = false;
          selectedRate.sort = rateSelected.sort;
        } else {
          selectedRate = {
            ...selectedRate,
            ...{
              name: "Your Price",
              description: "",
              rateCode: "",
              rateType: "",
              MultiRates: true,
              sort: null,
            },
          };
        }

        selectedPrice += parseFloat(rateSelected?.roomRateWithFees);
        Object.values(room.room.Rates).forEach(function (rate) {
          // Filter out the currently selected rate.
          // if (isMultiRoom && rateSelected.RateCode !== rate.RateCode) {
          rateArray.push(rate.RateCode);

          // }
        });
        rateArrays.push(rateArray);
      }
    });
    if (rateArrays.length) {
      // Find common rates among rooms.
      offerCodes = intersection(...rateArrays);
    }
    if (offerCodes.length) {
      roomsArray.forEach(function (room) {
        const roomRates = [];
        if (room.room) {
          room.room.Rates.forEach(function (rate) {
            if (offerCodes.includes(rate.RateCode)) {
              roomRates.push(rate);
            }
          });
          roomOffers[room.id].Rates = convertArrayToObject(
            roomRates,
            "RateCode"
          );
        }
      });
    }
    let commonOffers = {};
    const offerCount = Object.values(roomOffers).length;
    Object.values(roomOffers).forEach(function (roomOffer, _index) {
      const offerRates = Object.values(roomOffer.Rates);
      offerRates.forEach(function (offerRate) {
        const match = Object.keys(commonOffers).includes(offerRate.RateCode);
        if (!match) {
          const commonOffer = {
            RateCode: offerRate.RateCode,
            rateType: offerRate.rateType,
            name: offerRate.name,
            description: offerRate.description,
            roomRate: parseFloat(offerRate.roomRate),
            roomRateWithFees: parseFloat(offerRate?.roomRateWithFees),
            feeItems: offerRate?.feeItems ? offerRate?.feeItems : [],
            sort: offerRate.sort,
            currencyCode: offerRate.currencyCode,
            cancelPolicy: offerRate.cancelPolicy,
            guarantee: offerRate.guarantee,
          };
          commonOffers = { ...commonOffers, [offerRate.RateCode]: commonOffer };
        } else {
          // Add to the existing rate total.
          commonOffers[offerRate.RateCode].roomRate += parseFloat(
            offerRate.roomRate
          );
          commonOffers[offerRate.RateCode].roomRateWithFees += parseFloat(
            offerRate?.roomRateWithFees
          );
        }
      });
    });
    const averageOffers = [];
    Object.values(commonOffers).forEach(function (commonOffer) {
      // Convert RoomRate totals to average nightly rate.
      commonOffer.roomRate = commonOffer.roomRate / offerCount;
      commonOffer.roomRateWithFees = commonOffer?.roomRateWithFees / offerCount;
      averageOffers.push(commonOffer);
    });
    selectedRate.roomRate = selectedPrice / roomsArray.length;
    selectedRate.roomRateWithFees = selectedPrice / roomsArray.length;
    // selectedRate.Active = true;
    setCurrentRate(selectedRate);

    // averageOffers.push(selectedRate);
    averageOffers.sort((f, s) => {
      if (f.sort && s.sort) {
        return parseInt(f.sort) > parseInt(s.sort) ? 1 : -1;
      } else {
        if (f.sort && !s.sort) {
          return -1;
        }
        if (!f.sort && s.sort) {
          return 1;
        }
        if (!f.sort && !s.sort) {
          return f?.roomRateWithFees - s?.roomRateWithFees;
        }
      }
    });
    // averageOffers.sort((f, s) => {
    //   if (f.Sort != s.Sort ) {
    //     return f.Sort > s.Sort ? 1 : -1;
    //   } else {
    //     return f.RoomRate - s.RoomRate;
    //   }
    // });

    // move selected offer to top
    if (moveSelectedOfferToTop) {
      const selectedOfferIndex = averageOffers.findIndex(
        (averageOffers) => averageOffers.RateCode == selectedRate.RateCode
      );
      if (selectedOfferIndex != -1) {
        const topOffer = averageOffers.splice(selectedOfferIndex, 1);
        topOffer && averageOffers.unshift(topOffer[0]);
        // setMoveSelectedOfferToTop(false);
        const offersOrder = averageOffers.map((o) => o.RateCode);
        setOffersSortOrder(offersOrder);
      }
    } else if (offersSortOrder) {
      averageOffers.sort(function (a, b) {
        return (
          offersSortOrder.indexOf(a.RateCode) -
          offersSortOrder.indexOf(b.RateCode)
        );
      });
    }

    const getPoints = async (memberId: string) => {
      const response = await getMemberPoints(memberId);
      if (response?.memberPointsData && response?.memberPointsData?.available) {
        dispatch(setMemberPointsTally(response.memberPointsData.available));
        return response.memberPointsData.available;
      } else {
        return 0;
      }
    };

    const isRedemptionRateCodeInArray = averageOffers.some((obj) =>
      TP_REDEMPTION_RATE.includes(obj.RateCode)
    );

    if (isRedemptionRateCodeInArray && !isMultiRoom) {
      if (isLoggedIn) {
        let isEligible = false;
        const redemptionItem =
          rates[checkout.HotelCode as string]?.redemptionItem;
        if (
          redemptionItem &&
          redemptionItem?.currencyRequired &&
          memberProfile?.memberId
        ) {
          getPoints(memberProfile?.memberId).then((memberPoints) => {
            const numNights = differenceInDays(
              new Date(search.checkout),
              new Date(search.checkin)
            );
            isEligible = checkUserRedemptionEligiblity(
              parseInt(memberPoints),
              parseInt(redemptionItem?.currencyRequired),
              numNights
            );
            const redemptionRateIndex = averageOffers.findIndex((obj) =>
              TP_REDEMPTION_RATE.includes(obj.RateCode)
            );

            const redemptionRateObject = averageOffers.splice(
              redemptionRateIndex,
              1
            )[0];

            if (isEligible) {
              // Adding rate to top
              averageOffers.unshift(redemptionRateObject);
            } else {
              //Adding rate to bottom
              averageOffers.push(redemptionRateObject);
            }

            const isDiscountApplied =
              search.promotionCode || search.groupCode || search.discount
                ? true
                : false;
            if (!isDiscountApplied) {
              if (!redemptiomRateFilterFlag && moveSelectedOfferToTop) {
                const newSelectedRate = {
                  ...averageOffers[0],
                  Active: true,
                  MultiRates: false,
                };
                setCurrentRate(newSelectedRate);
                setredemptiomRateFilterFlag(true);
                const rateCode = averageOffers[0].RateCode;
                const selectedOffer = offers.filter(
                  (o) => o.RateCode == rateCode
                )[0];
                setCurrentRate(selectedOffer);
                let currentRooms = { ...rooms };
                Object.values(currentRooms).forEach(function (room: IRoom) {
                  const _rate = room.room.Rates.filter(
                    (roomRate: { RateCode: any }) =>
                      roomRate.RateCode === rateCode
                  )[0];
                  const _services = null;
                  currentRooms = {
                    ...currentRooms,
                    [room.id]: { ...room, rate: _rate, services: _services },
                  };
                });
                const newCheckout = { ...checkout, Rooms: currentRooms };
                dispatch(setCheckout(newCheckout));
              }
            }
            setOffers(averageOffers);
          });
        } else {
          //Filtering Redemption rates out if not able to calculate eligibility for a user.
          const filteredArray = averageOffers.filter(
            (obj) => !TP_REDEMPTION_RATE.includes(obj.RateCode)
          );
          setOffers(filteredArray);
        }
      } else {
        //Filtering Redemption rates out if user is not logged in
        const filteredArray = averageOffers.filter(
          (obj) => !TP_REDEMPTION_RATE.includes(obj.RateCode)
        );
        setOffers(filteredArray);
      }
    } else {
      setOffers(averageOffers);
    }
  }, [checkout]);

  useEffect(() => {
    if (offers) {
      setMoveSelectedOfferToTop(false);
    }
  }, [offers]);

  const handleSelectRate = (event: React.MouseEvent<HTMLElement>) => {
    setMoveSelectedOfferToTop(false);
    const rateCode = event.target.value;
    const selectedOffer = offers.filter((o) => o.RateCode == rateCode)[0];
    setCurrentRate(selectedOffer);
    let currentRooms = { ...rooms };
    Object.values(currentRooms).forEach(function (room: IRoom) {
      const _rate = room.room.Rates.filter(
        (roomRate: { RateCode: any }) => roomRate.RateCode === rateCode
      )[0];
      currentRooms = {
        ...currentRooms,
        [room.id]: { ...room, rate: _rate },
      };
    });
    const newCheckout = { ...checkout, Rooms: currentRooms };
    dispatch(setCheckout(newCheckout));
  };
  if (offers.length) {
    return (
      <div className={`pt-4 pb-4 rounded ${selectRateContainer} `}>
        <Row className="align-items-center mb-3 g-0">
          <Col xs="auto">
            <h4 className={`mb-0 selectRateText`}>Select a Rate</h4>
          </Col>
        </Row>
        <RateList>
          {currentRate?.MultiRates && (
            <RateListItem
              offerId={`current-rate`}
              crsCode={checkout.HotelCode}
              rate={currentRate}
              currentRate={currentRate}
              handleSelectRate={handleSelectRate}
              perNight={perNight}
            />
          )}
          {offers.map((rate, i) => {
            return (
              <RateListItem
                offerId={`offer-${i + 1}`}
                crsCode={checkout.HotelCode}
                key={`rl${i}-${rate.RateCode}`}
                rate={rate}
                currentRate={currentRate}
                handleSelectRate={handleSelectRate}
                perNight={perNight}
              />
            );
          })}
        </RateList>
      </div>
    );
  }
  return null;
};
export default CheckoutOffers;
