import * as React from "react";
import { Row } from "react-bootstrap";
import { container, styledImage, zoomIcon } from "./RoomHeader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";

export const Container: React.FC<any> = (props) => {
  return <Row className={`${container} mb-2`}>{props.children}</Row>;
};
export const StyledImage: React.FC<any> = (props) => {
  return (
    <>
      <img
        src={props.src}
        alt={props.alt}
        id={props.id}
        className={styledImage}
      ></img>
      {props.carouselImages &&
        props.carouselImages.length > 1 &&
        !props.isMultiRoom && (
          <FontAwesomeIcon
            id="gallery-icon"
            icon={faExpand}
            className={zoomIcon}
            onClick={() => {
              props.setShowZoomedImage(true);
            }}
          />
        )}
    </>
  );
};
